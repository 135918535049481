<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mb-3 text-right">
        <v-flex xs6 sm4 md4 lg4 class="mb-1 text-left">
          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            small
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm8 md8 lg8 class="mb-1">
          <v-btn
            small
            outlined
            class="mr-3"
            @click="delDialog = true"
            :color="$store.state.secondaryColor"
          >
            <v-icon left small>delete</v-icon>
            {{ $t("delete profile") }}
          </v-btn>
          <v-btn
            small
            outlined
            class="mr-3"
            @click="viewStores()"
            :color="$store.state.primaryColor"
          >
            <v-icon left small>home</v-icon>
            {{ $t("view stores") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-dialog v-model="delDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="justify-center">
            <v-btn
              class="my-5 text-center"
              outlined
              large
              fab
              color="red darken-4"
            >
              <v-icon>warning</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="text-center">
            <!-- <v-icon>delete</v-icon> -->
            <p>{{ $t("are you sure") }}?</p>
            <p>
              {{ $t("do you really want to delete") }}
              <span class="font-weight-medium warning--text text--darken-2">
                {{ fullnames() }} </span
              >?
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :color="$store.state.secondaryColor"
              text
              small
              @click="delDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :color="$store.state.primaryColor"
              text
              small
              @click="deleteOwner"
            >
              {{ $t("confirm delete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        shaped
        top
        rounded="pill"
        v-model="snackbar"
        :timeout="timeout"
        color="blue-grey"
      >
        {{ $t("deleted") }} !

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            {{ $t("close") }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-layout row wrap justify-space-around>
        <v-flex xs12 sm12 md3 lg3 class="">
          <v-card class="mx-auto" max-width="344">
            <v-card-title>
              {{ $t("profile picture") }}
              <v-spacer></v-spacer>
              <v-tooltip top color="black">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    right
                    :color="$store.state.secondaryColor"
                    @click="editOwner()"
                    >fas fa-edit</v-icon
                  >
                </template>
                <span>{{ $t("edit profile") }}</span>
              </v-tooltip>
            </v-card-title>
            <v-img :src="details.avatar" height="200px"></v-img>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md4 lg4 class="px-1">
          <v-list dense class="elevation-1 pa-0">
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold">{{ $t("name") }}: </span></v-col
              >
              <v-col cols="6" class="text-right">
                <span class="">{{ fullnames() }} </span>
              </v-col>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("gender") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span class="">{{ details.gender }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("email") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><u
                  ><em class="primary--text">{{ details.email }} </em></u
                ></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("telephone") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.telephone }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("username") }}:</span
                ></v-col
              >
              <v-col cols="6" class="text-right"
                ><span> {{ $route.params.id }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("password") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span> {{ details.password }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("registered on") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ DateFormat(details.dateCreated) }} </span></v-col
              >
            </v-list-item>
          </v-list>
        </v-flex>

        <v-flex xs12 sm6 md4 lg4 class="px-1">
          <!-- <h3>Address</h3>  -->
          <v-list dense class="elevation-1 pa-0">
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("country") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.country }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("county") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.county }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold">{{ $t("city") }}: </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.city }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("street") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.street }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("estate") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.estate }} </span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("building ") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.building }}</span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("house-suite-number") }}:
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.no }}</span></v-col
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="caption justify-space-between">
              <v-col cols="6"
                ><span class="font-weight-bold"
                  >{{ $t("PO BOX") }} :
                </span></v-col
              >
              <v-col cols="6" class="text-right"
                ><span>{{ details.address.pobox }} </span></v-col
              >
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
      <!-- {{details}} -->
    </v-container>
    <v-row
      v-show="pleaseWait"
      class="fill-height mt-7"
      align-content="center"
      justify="center"
    >
      <v-col class="text-subtitle-1 text-center" cols="12">
        {{ $t("loading... please wait") }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          :color="$store.state.primaryColor"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import db from "@/plugins/fb";
import format from "date-fns/format";

export default {
  data: () => ({
    details: "",
    delDialog: false,
    snackbar: false,
    pleaseWait: true,
    timeout: 2000,
  }),

  created() {
    this.getOwnerDetails();
  },

  methods: {
    DateFormat(data) {
      var date = data.toDate();
      return format(date, "eee dd-MMM-yyyy hh:mm");
    },

    getOwnerDetails() {
      db.collection("owners")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.details = doc.data();
          this.getUsername();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    fullnames() {
      return (
        this.details.names.first +
        " " +
        this.details.names.middle.substr(0, 1) +
        ". " +
        this.details.names.last
      );
    },

    getUsername() {
      db.collection("users")
        .where("username", "==", this.$route.params.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.details.password = snaps.data().password;
          });
        });
    },

    viewStores() {
      this.$router.push({
        name: "viewStores",
        params: { id: this.$route.params.id },
      });
    },

    editOwner() {
      this.$router.push({
        name: "EditOwner",
        params: { id: this.$route.params.id },
      });
    },

    deleteOwner() {
      db.collection("owners")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(function () {
            window.history.back();
          }, 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
